import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

const TutorsLogo = loadable(() =>
	import("@components/Global/Custom/Logo/tutors/TutorsLogo")
);
const LessonsLogo = loadable(() =>
	import("@components/Global/Custom/Logo/lessons/LessonsLogo")
);
const HomeGuideLogo = loadable(() =>
	import("@components/Global/Custom/Logo/homeguide/HomeGuideLogo")
);
const ThervoLogo = loadable(() =>
	import("@components/Global/Custom/Logo/thervo/ThervoLogo")
);
const FashLogo = loadable(() =>
	import("@components/Global/Custom/Logo/fash/FashLogo")
);
const BetterCareLogo = loadable(() =>
	import("@components/Global/Custom/Logo/bettercare/BetterCareLogo")
);
const TrustedCareLogo = loadable(() =>
	import("@components/Global/Custom/Logo/trustedcare/TrustedCareLogo")
);
const DealflowLogo = loadable(() =>
	import("@components/Global/Custom/Logo/dealflow/DealflowLogo")
);
const RVgoLogo = loadable(() =>
	import("@components/Global/Custom/Logo/rvgo/RVgoLogo")
);
const VetyLogo = loadable(() =>
	import("@components/Global/Custom/Logo/vety/VetyLogo")
);
const TaloLogo = loadable(() =>
	import("@components/Global/Custom/Logo/talo/TaloLogo")
);
const LawfulLogo = loadable(() =>
	import("@components/Global/Custom/Logo/lawful/LawfulLogo")
);

const LawnmastersLogo = loadable(() =>
	import("@components/Global/Custom/Logo/lawnmasters/LawnmastersLogo")
);

const { SITE_NAME } = process.env;

const Logo = ({ width, height, filter, className, ...props }) => {
	return (
		<>
			{SITE_NAME === "tutors" && (
				<TutorsLogo
					className={className}
					{...props}
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "homeguide" && (
				<HomeGuideLogo
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "lessons" && (
				<LessonsLogo
					className={className}
					{...props}
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "thervo" && (
				<ThervoLogo
					className={className}
					{...props}
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "fash" && (
				<FashLogo
					className={className}
					{...props}
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "bettercare" && (
				<BetterCareLogo
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "trustedcare" && (
				<TrustedCareLogo
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "dealflow" && (
				<DealflowLogo
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "rvgo" && (
				<RVgoLogo
					className={className}
					{...props}
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "vety" && (
				<VetyLogo
					className={className}
					{...props}
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "talo" && (
				<TaloLogo
					className={className}
					{...props}
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "lawful" && (
				<LawfulLogo
					className={className}
					{...props}
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "lawnmasters" && (
				<LawnmastersLogo
					style={{ width: width, height: height, filter: filter }}
				/>
			)}
		</>
	);
};

Logo.propTypes = {};
export default Logo;
